@use "_mixins";
@use "_extends";
@use "_colors";

body{
    &.lock{
        .sticky-footer-wrapper{
            @include mixins.mq(1200){z-index: 101!important;}          
        }
        #chat-widget-container{z-index: -1 !important;}
    }
}
main{
    .sticky-footer-wrapper{position: -webkit-sticky; position: fixed; left:0; right:0; bottom:0; z-index:101; display: flex; flex-direction: column; align-items: center; max-width: 660px; margin: auto;
        @include mixins.mq(1200){z-index: 9999999999;}
        &.active{z-index: 101 !important;}
        &.results { max-width: 100%; }
    }
    .sticky-footer{padding-bottom:29px; width:100%;
        .container{display: flex; justify-content: center; gap:18px; padding: 0 10px;
            .sticky-pill{min-height: 73px;               
                &__icon{padding: 13px 17px 13px; border-radius: 8px; margin-right:13px; display: flex; align-items: center; justify-content: center; gap:6px;
                    &.gold{background-color: colors.$gold;
                        img{
                            &.desktop{
                                @include mixins.mq(600){display:none;}
                            }
                            &.mobile{
                                @include mixins.mqmin(600){display:none;}
                            }
                        }
                        .small-icon{display:none;}  
                    }
                    &.blue{font-size: 1.6rem; color: var(--color-off-black); background-color: var(--color-yellow); font-family: "Magazine Grotesque", sans-serif; position:relative; transition: background-color 0.3s, opacity 0.3s ease; margin-right: 0; margin-left: auto;
                        label{transition: width 0.3s 0s; overflow: hidden;
                            &.active{width: 58px; transition: width 0.3s .2s;}
                            @include mixins.mq(1100){width: auto;
                                //&.active{width: auto;}
                            }
                        }
                        .set-loading{position:absolute; top:0; right:0; bottom:0; left:0; z-index:2;}
                        &.active{background-color: #bdbdbd; opacity: 0.4; cursor: default;
                            &.valid{background-color: var(--color-yellow); opacity: 1; cursor: pointer;}
                            @include mixins.mqmin(1200){
                                &>span{display: none; }
                            }                          
                        }
                        @include mixins.mq(1200){background-color: var(--color-yellow) !important; opacity: 1 !important; cursor: pointer !important;
                           .set-loading{display:none;}
                        }  
                        span{padding-right: 10px; transition: 0s !important;}
                        @include mixins.mq(600){width: auto; height: auto;
                            //span{padding-right: 10px}
                        }                                              
                    }
                    img{height: 32px; width: 32px;}
                    label{@include mixins.font(1.6, 1.6, 1.4rem, 700, 0rem, var(--color-off-black), "Magazine Grotesque"); cursor:pointer}
                    //@include mq(1375){margin-right: 0;}
                }         
                svg { margin-right:10px; }
                .pill-info{display: flex; align-items: center; gap:13px;
                    label{@include mixins.font(1.4, 1.4, 1.8rem, 400, 0, var(--color-off-black)); cursor: pointer; white-space: nowrap;}
                    span{display: block; @include mixins.font(1.6, 1.6, 2rem, 700, 0rem, var(--color-off-black)); display: block;
                        i{font-style:normal;
                            @include mixins.mq(600){display:none;}
                        }
                        
                    }
                    
                    &__tooltip{cursor: default; position: absolute; left: 15px; bottom: 109px; width: 249px; height: 201px; padding: 24px 23px; background-color: #fff; border: 1px solid #F7F7F7; box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.2); border-radius: 8px; opacity: 0; pointer-events: none; transform: translateY(4px); transition: 0.2s;
                        label{@include mixins.font(2, 2, 2.8rem, 700, 0rem, colors.$darkGray, "Magazine Grotesque"); text-transform: none; cursor: default; white-space: inherit;}
                        p{@include mixins.font(1.2, 1.2, 1.75rem, 400, 0rem, var(--color-off-black)); margin: 7px 0 19px;}
                        span{@include mixins.font(1.2, 1.2, 1.63rem, 400, 0rem, var(--color-off-black)); cursor: pointer; display: inline-block; position:relative; padding: 6px 17px 6px 25px; border-radius: 20px; background-color: #f7f7f7;
                            &:after{content: ''; position: absolute; top:10px; left:12px; height: 8px; width: 8px; background-color: #e23535; border-radius: 100%;}
                            &.online{
                                &:after{background-color: #4CA771;}
                            }
                        }
                        .tooltip-close{position: absolute; top:13px; right: 12px; cursor: pointer; width: 12px;}
                        &:after{content: ''; position: absolute; bottom: -9px; left: 22px; height: 16px; width: 16px; background-color: #fff; transform: rotate(45deg);}
                        &.active{opacity: 1; pointer-events: all; transform: translateY(0);}
                        @include mixins.mq(1230){left:5px; bottom:90px;} 
                        @include mixins.mq(600){left:4px; } 
                        @include mixins.mq(360){left:-7px; bottom:80px;} 
                    }   
                     
                    
                    &:nth-child(2){
                        @include mixins.mq(1200){max-width:335px;}                           
                    }

                    &.guide-price{width: 100%; margin-right: 24px; transition: 0.2s .3s; overflow: hidden; transform: translateY(0px); opacity: 1;
                        @include mixins.mq(1200){margin-right: 10px;}
                        span{white-space: nowrap;}
                    }
                   
                   
                    
                } 

                form{display: flex; align-items: center; gap:0px; flex:1; transition:transform 0s 0s, opacity 0s 0s, max-width 0s 0s ease-out; opacity:0; transform:translateY(6px); max-width:0; pointer-events:none;
                    .form-input{padding: 17px 20px 15px 20px; flex:1; cursor: pointer; position: relative;
                        &>label{@include mixins.font(1.2, 1.2, 1.2rem, 500, 0rem, rgba(102, 102, 102, 1)); text-transform: uppercase; display: block; margin-bottom: 7px; cursor: pointer;}
                        &>span{@include mixins.font(1.4, 1.8, 2.2rem, 400, 0rem, var(--color-off-black)); font-size: clamp(1.4rem, 1.5vw, 1.6rem); text-overflow: ellipsis; overflow: hidden; white-space: nowrap; display: block; max-width: 99%;}
                        &:not(&:nth-child(2)){border-left: 1px solid #E3E4E5;}
                        &:first-child{flex:1;
                            @include mixins.mq(1200){flex:1;}
                        }
                        &.error{
                            &>label, &>span{color: #f01111;}
                        }
                        &:last-child{flex:0.9;}
                        .form-popup{position: absolute;z-index: 2; bottom:90px; background-color: white; border-radius: 12px; box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.17); opacity: 0; transform: translateY(5px); pointer-events: none; transition: opacity 0.3s 0s, transform 0.3s 0s ease-out;
                            h4, .h4{font-size: 1.4rem; font-size: clamp(1.4rem, 1.4vw, 1.4rem); line-height: 1.9rem; font-weight: 700; color: var(--color-off-black); letter-spacing: 0.04rem; font-family: "Archivo"; text-transform: uppercase; text-align: center;}
                       
                            &.dates{width: 290px; padding: 20px; 
                                .duration{display: flex; text-align: center; justify-content: center; gap:21px; margin: 20px 0; border-bottom: 1px solid colors.$grey8; padding-bottom: 20px;
                                    button{padding: 0; flex: 0 0 36px; height: 36px; border-color: colors.$grey8; margin-top: 7px; touch-action: manipulation;
                                        &:disabled{opacity: 0.4; pointer-events: none;}
                                    }
                                    label{@include mixins.font(1.4, 1.4, 1.4rem, 400, 0, colors.$grey4);}
                                    p{@include mixins.font(1.6, 1.6, 1.6rem, 400, 0, var(--color-off-black)); margin: 8px 0 0;
                                        span{display: inline-block; color: var(--color-off-black); overflow: visible;}
                                    }                               
                                    @media(max-height:700px){padding-bottom: 30px; margin: 30px 0;}
                                    @media(max-height:630px){padding-bottom: 20px; margin: 20px 0;}
                                }   
                                @media(max-height:850px) and (min-width: 1100px){padding: 20px;
                                    .duration{margin: 15px 0; padding-bottom: 15px;}
                                    #calendar .vc-container .vc-pane-layout .vc-pane .vc-header {margin: 0 0 12px;}
                                    #calendar .vc-container .vc-pane-layout .vc-pane .vc-weeks .vc-day {height: 36px;
                                        span{font-size: 1.4rem;}                                        
                                    }
                                } 
                                #calendar .vc-container .vc-pane-layout .vc-pane .vc-weeks .vc-day {
                                    span{
                                        @include mixins.mq(1200){width: 100%;}
                                    }
                                    @include mixins.mq(1200){width: 100%;}
                                    .vc-highlights .vc-day-layer .vc-highlight{
                                        @include mixins.mq(1200){width: 100%;}
                                    }          
                                }     
                              
                            }
                            .done{@include mixins.font(1.4, 1.4, 1.4rem, 600, 0, var(--color-off-black)); background-color: var(--color-yellow); border-radius: 8px; width: 100%; height: 42px; border:none; 
                                &.a-button{display: inline-block; text-align: center; line-height: 42px;}
                            }
                            &.pax{padding: 20px; width:355px; 
                                &>div{height: 100%;}
                                h4{margin-bottom: 20px;}                                
                                .more-rooms{
                                    .close-icon {position: absolute; top: 20px; right: 30px; font-size:1.6rem; cursor:pointer;}
                                }
                               .pax-people{display: grid; gap:14px; border-bottom: 1px solid colors.$grey8; padding-bottom: 20px;  padding-top: 20px;
                                    &::-webkit-scrollbar {width: 5px;}       
                                    &::-webkit-scrollbar-track {background-color: white;}       
                                    &::-webkit-scrollbar-thumb {background-color: colors.$darkGray; border-radius: 10px;}

                                    .child-ages-message{@include mixins.font(1.4, 1.4, 1.4rem, 700, 0, var(--color-off-black)); margin-top: 3px;}

                                    .pax-children{display: flex; flex-direction: column; gap:10px; max-height: 20vh; overflow-y: auto; padding-right: 5px;
                                        &::-webkit-scrollbar {width: 5px;}       
                                        &::-webkit-scrollbar-track {background-color: white;}       
                                        &::-webkit-scrollbar-thumb {background-color: colors.$darkGray; border-radius: 10px;}
                                    }
                                    
                                    .pax-row{display: flex; justify-content: space-between; align-items: center;
                                        .pax-person{
                                            label{@include mixins.font(1.6, 1.6, 1.6rem, 400, 0, var(--color-off-black));}
                                            span{@include mixins.font(1.1, 1.1, 1.1rem, 400, 0, colors.$grey4); display: block; margin-top: 2px;}
                                        }
                                        .pax-controls{display: flex; gap:16px; align-items: center;
                                            select{height: 36px; border-radius: 2px; border: 1px solid colors.$grey8; padding: 0 15px; width: 112px; text-align: left; font-size: 1.6rem; cursor: pointer; background: url("../../images/icons/chevron-down-black.svg") center right 15px no-repeat white; background-size: 12px;
                                                option{color: colors.$darkGray;
                                                    &:first-child{color:#8E8C9A;}
                                                }
                                            }
                                            span{@include mixins.font(1.6, 1.6, 1.6rem, 400, 0, var(--color-off-black));}
                                            button{min-width: 36px; height: 36px; border-color: colors.$grey8; padding: 0;
                                                &:disabled{opacity: 0.4; pointer-events: none;}
                                            }
                                        }
                                    }
                               }
                               .add-more{text-align: center; margin: 20px 0 20px;
                                    label{@include mixins.font(1.3, 1.3, 1.3rem, 400, 0, var(--color-off-black)); cursor: pointer;}
                               }
                            }
                
                            &.options{max-height: 325px; overflow-y: auto; width:230px;
                                &::-webkit-scrollbar {width: 5px;}       
                                &::-webkit-scrollbar-track {background-color: transparent; border-radius: 10px;}       
                                &::-webkit-scrollbar-thumb {background-color: colors.$darkGray; border-radius: 10px;}
                                ul{
                                    li{@include mixins.font(1.6, 1.6, 1.6rem, 400, 0, colors.$darkGray); padding: 24px 20px;
                                        &:not(&:last-child){border-bottom: 1px solid colors.$grey8;}
                                    }
                                }
                                @include mixins.mq(700){max-height: 85dvh; max-height: 85vh;}
                            }
                            button{
                                &#dates-done, &#pax-done{font-size: 1.4rem; font-size: clamp(1.6rem, 1.6vw, 1.6rem); line-height: 1.4rem; font-weight: 600; color: var(--color-off-black); letter-spacing: 0;font-family:"Magazine Grotesque", sans-serif; background-color: var(--color-yellow); border-radius: 8px; width: 100%; height: 40px; border: none;}
                            }
                          
                            &.active{opacity: 1; transform: translateY(0); pointer-events: all; -webkit-overflow-scrolling: none; overscroll-behavior: none; }
                                @include mixins.mq(700){max-width: 100%; width: calc(100% - 142px) !important; right: 77px; top:192px; left:77px; transform: translate(-6px, 0px) !important;}
                                @include mixins.mq(860){right: 42px; left:42px; //width: calc(100% - 72px) !important;
                               
                            }
                            @include mixins.mq(1230){bottom:67px;}
                            @include mixins.mq(1200){position: fixed; top:auto; right:0; bottom:0; left:0; width: 100% !important; border-radius: 12px 12px 0 0; transition: opacity 0s 0s, transform 0.3s 0s ease-out !important;}
                            @include mixins.mq(700){transform: translate(0px, 100%) !important;
                                &.active{transform: translate(0px, 0%) !important;}
                            }
                            @include mixins.mq(860){padding: 30px 30px 20px !important;}     
                            @include mixins.mq(374){padding: 20px 20px 20px !important;}   
                        }
                    } 
                    .mobile-search-btn{background-color:var(--color-yellow); padding: 0 10px; font-size:1.6rem; font-family: "Magazine Grotesque", sans-serif; font-weight: 700; color: var(--color-off-black); border-radius:8px; border:none; width: calc(100% - 30px); margin: 0 auto; height:40px;
                        &.active{background-color: #bdbdbd; opacity: 0.4; cursor: default;
                            &.valid{background-color: var(--color-yellow); opacity: 1; cursor: pointer;}
                        } 
                        @include mixins.mqmin(1200){display:none;}
                    }

                    .sticky-mobile-shroud{position: fixed; top:0; right:0; bottom:0; left:0; z-index: 2; background-color: rgba(black, 0.4); display: none;
                        &.option-active{
                            @include mixins.mq(1200){display: block;}
                            @include mixins.mq(600){background-color: rgba(black, 0.2);}
                        }
                    }     
                    
                    &.option-active{transform: none !important; transition-duration: 0s !important;}

                    @include mixins.mq(1200){flex:none; gap:20px; flex-direction:column; width: auto; transform:translateY(4px); transition-duration:0.2s; max-width:none; padding:16px 0; position: absolute; background-color: var(--color-yellow-light); bottom: 80px; left: 0; right: 0; align-items:flex-start; border:1px solid rgba(204, 204, 204, 1); border-radius: 8px; 
                        .form-input{border-left: none !important; width:100%; padding-left:0; padding: 0 16px 10px 16px;
                            span{max-width:none;}
                            &:not(&:last-of-type){border-bottom: 1px solid rgba(204, 204, 204, 1);}
                            &:last-of-type{padding-bottom: 0;}
                        }                        
                    }   
                    @include mixins.mq(600){position:fixed; bottom:16px; left:20px; right:20px; z-index:3; transition-duration:0.4s; transform: translateY(calc(100% + 40px)); opacity:1;
                        .form-input{flex:none !important;}
                    }
                    @include mixins.mq(400){bottom:10px;}
                    @include mixins.mq(360){left:10px; right:10px;}
                }

                .mobile-shroud{position:fixed; top:0; right:0; bottom:0; left:0; z-index:-1; background-color:rgba(black, 0); opacity:0; pointer-events:none; transition:0.3s;
                    @include mixins.mqmin(600){display:none;}
                }

               
                &.active{flex: 1; gap:10px; max-width: 1160px; padding-left: 7px; //show pill form 
                    &.two-removed{ max-width: 860px; }
                    &.one-removed{ max-width: 1000px; }
                    @include mixins.mq(1230){flex:none;}
                    //.sticky-pill__icon.blue{padding-right:13px;
                        //@include mixins.mq(1200){padding-right: 10px;}  
                    //}
                    .pill-info{
                        &.guide-price{width: 0%; margin-left: 0; transition: 0s 0s ease-in;
                            @include mixins.mq(1200){width: auto; //display:flex; 
                                &.active{width: auto; } 
                            }   
                            @include mixins.mqmin(1200){ transform: translateY(6px); opacity: 0;}
                        }
                    } 

                    form{transform: translateY(0); max-width:1030px; opacity:1; pointer-events:all; transition: transform 0.4s 0.26s, opacity 0.4s 0.26s, max-width 0.4s 0s ease-out} 

                    .mobile-shroud{background-color:rgba(black, 0.4); opacity:1; pointer-events:all;}

                    @include mixins.mq(1200){gap:0px; padding-right:25px; padding-left: 25px;
                        &.hidePill{
                            form{bottom: -500px;}
                        }
                        form{transition: transform .4s .0s, opacity .4s .0s, max-width .4s 0s ease-out;}
                    }                                                
                                             
                    @include mixins.mq(600){padding-left: 30px; padding-right: 30px;}
                    @include mixins.mq(374){padding-left: 20px; padding-right: 20px;}    
                }       

                @include mixins.mq(600){min-height: 72px; width: 100%; justify-content: space-between; padding-left: 25px;          
                    &:not(&.two-removed){
                        .pill-info{display: none;}
                        .sticky-pill__icon{
                            &.gold{margin-right: 0; height: 72px; width: 72px;
                                .big-icon{height: 35px; width: 35px;}
                            }
                        }
                    }  
                    &.two-removed{
                        .pill-info{
                            &.guide-price{width: auto;}
                        }
                    }                 
                }    
                @include mixins.mq(360){min-height: 65px;   
                    &:not(&.two-removed){background-color: transparent; box-shadow: none;   
                        .sticky-pill__icon{
                            &.gold{margin-right: 0; height:50px; width:50px;
                                .big-icon{height: 27px; width: 27px;}
                            }
                        }
                    }                   
                }       

                &.centered{
                    .pill-info.guide-price{margin-right: 0;
                        &>div{width: 100%;}                      
                    }
                    @include mixins.mq(600){justify-content: center;}
                }

                @include mixins.mq(600){padding-left: 30px; padding-right: 30px;}
                @include mixins.mq(374){padding-left: 20px; padding-right: 20px;}
            } 
            
            .result-details{display: flex; align-items: center; gap:31px; justify-content: space-between; flex: 1;
                &>div{
                    &:nth-child(2){display:flex; gap:30px; align-items: center;
                        @include mixins.mq(1200){gap:20px;}
                        @include mixins.mq(1200){align-items:center;}
                    }
                }
               
                .talk-pill{
                    &__tooltip{cursor: default; position: absolute; left: 10px; bottom: 89px; width: 249px; height: 201px; padding: 24px 23px; background-color: #fff; border: 1px solid #F7F7F7; box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.2); border-radius: 8px; opacity: 0; pointer-events: none; transform: translateY(4px); transition: 0.2s;
                        label{@include mixins.font(2, 2, 2.4rem, 700, 0rem, colors.$darkGray, "Magazine Grotesque"); text-transform: none; cursor: default;}
                        p{@include mixins.font(1.2, 1.2, 1.68rem, 400, 0rem, var(--color-off-black)); margin: 7px 0 19px;}
                        span{@include mixins.font(1.2, 1.2, 1.63rem, 400, 0rem, var(--color-off-black)); cursor: pointer; display: inline-block; position:relative; padding: 6px 17px 6px 25px; border-radius: 20px; background-color: #f7f7f7;
                            &:after{content: ''; position: absolute; top:10px; left:12px; height: 8px; width: 8px; background-color: #e23535; border-radius: 100%;}
                            &.online{
                                &:after{background-color: #4CA771;}
                            }
                        }
                        .tooltip-close{position: absolute; top:13px; right: 12px; cursor: pointer; width: 12px;}
                        &:after{content: ''; position: absolute; bottom: -9px; left: 22px; height: 16px; width: 16px; background-color: #fff; transform: rotate(45deg);}
                        &.active{opacity: 1; pointer-events: all; transform: translateY(0);}
                    }
                    @include mixins.mq(1230){padding: 0;
                        &>div{
                            &:nth-child(2){display: none;}
                        }
                    }
                    @include mixins.mq(1200){position: absolute; bottom:0; left:16px; z-index: 2;}                    
                }
                ul{display: flex; align-items: center;
                    &::-webkit-scrollbar {height: 0px; width: 0;}       
                    &::-webkit-scrollbar-track {background-color: #f7f7f7;}       
                    &::-webkit-scrollbar-thumb {background-color: colors.$darkGray; border-radius: 10px;}
                    li{@include mixins.font(1.4, 1.6, 1.9rem, 400, 0rem, var(--color-off-black)); margin: 0 10px; position: relative; font-weight: 700;
                        &:after{content: ""; position: absolute; top:8px; left:-11px; height: 4px; width: 4px; background-color:var(--color-off-black); border-radius: 100%;}    
                        &:first-child{margin-left:0;
                            &:after{content: none;}
                        }
                        &:last-child{margin-right: 0; color:#000; cursor: pointer; text-decoration:underline;
                            &:after{content: ""; width: 1px; height: 21px; top:-1px;}
                            @include mixins.mq(500){position: -webkit-sticky; position: sticky; right:0; top:0; background-color: white; padding-left: 10px; border-left:1px solid var(--color-off-black);
                                &:after{content: none;}
                            }
                        }                   
                    }
                }
                .talk-total{display: flex; flex-direction: column; justify-content: center;
                    label{@include mixins.font(1.8, 1.8, 100%, 400, 0rem, rgba(102, 102, 102, 1));
                        span{@include mixins.font(1.8, 1.8, 2.4rem, 700, 0rem, var(--color-off-black));}
                    }
                    small{display: flex; align-items: center; justify-content: flex-end; gap:5px; cursor: pointer; font-style: normal; font-size:1.4rem; color: rgba(102, 102, 102, 1); 
                        @include mixins.mq(1200) { justify-content: flex-start; }
                        i{@include mixins.font(1.2, 1.2, 1.2rem, 400, 0rem, var(--color-off-black));font-style: normal; }
                        img{width: 17px;}
                        @include mixins.mq(500){
                            i{display: none;}
                        }
                    }
                }
                button#next-stage{border-radius: 8px; border: none; height:40px; transition: background-color 0.3s, opacity 0.3s ease; padding:8px 16px; position:relative; @include mixins.font(1.6, 1.6, 1.6rem, 700, 0rem, var(--color-off-black)); cursor:pointer; display: flex; align-items: center; gap:7px; background-color: var(--color-yellow);
                    a{position:absolute; top:0; right:0; bottom:0; left:0;}                   
                    .loading-icon{opacity: 0; transform: translateY(0); display: flex; align-items: center; justify-content: center; position: absolute; top:0; right:0; bottom:0; left:0;
                        img{width: 28px;}
                    } 
                    img.arrow{opacity: 1;}
                    img.arrow, .loading-icon, &:before{transition: 0.2s ease-out;}                 
                    &.product:before{content: "Customise Holiday";
                        @include mixins.mq(1400){content: "Customise";}
                    }&.hotel:before{content: "Continue to flights";
                        @include mixins.mq(1400){content: "Flights";}
                    }&.flights:before{content: "Continue to passengers";
                        @include mixins.mq(1400){content: "Passengers";}
                    }&.passengers:before{content: "Continue to payment";
                        @include mixins.mq(1400){content: "Payment";}
                    }&.payment:before{content: "Book Now";
                        @include mixins.mq(1400){content: "Book Now";}
                    }                    
                    @include mixins.mq(1400){padding: 17px 18px;}
                    @include mixins.mq(1200){padding: 11px 18px; height:40px;}
                }
                &:first-child{gap:29px;
                    @include mixins.mq(1400){gap:20px;}
                    @include mixins.mq(1200){width: 100%; border-bottom: 1px solid #D3D4D5; padding-bottom: 15px; padding-inline: 16px;
                        ul{overflow-x: auto;
                            li{flex-shrink: 0;}
                        }
                    }
                    @include mixins.mq(500){padding-right: 55px;
                        ul{
                            li{
                                &:last-child{position: -webkit-sticky; position: sticky; right: 0; top: 0; background-color: white; padding-left: 10px; border-left: 1px solid #E3E4E5;}
                            }
                        }
                    }
                }
                &.disabled{
                    #next-stage{background-color: #bdbdbd; opacity: 0.4; cursor: default;}
                }
                &.loading{
                   #next-stage{pointer-events: none;
                        img{
                            &.arrow{opacity: 0; transform: translateY(-7px);}                            
                        }
                        .loading-icon{opacity: 1; transform: translateY(0); transition-delay: .15s;
                            img{-webkit-animation:spin 2s linear infinite forwards; animation:spin 2s linear infinite forwards;}
                        }
                        &:before{color:var(--color-yellow); transform: translateY(-7px);}
                   }
                }
                @include mixins.mq(1200){gap:20px;}
            }
            @include mixins.mq(1050){padding: 0;}        

        }
        @include mixins.mq(1050){height:auto; padding-inline: 0;} 
        
        @keyframes showResults {
            from{transform: translateY(155px); opacity:1;}
            to{transform: translateY(0); opacity:1;}
        }

        @keyframes hidePillTemp{
            from{bottom: -125px;}          
        }
              
        &.results{ background-color:#FDF8F3; padding:8px 16px; box-shadow: 0px -4px 10px -1px rgba(0, 0, 0, 0.1); animation: showResults 0.4s ease-out 0s both; height: 72px;
            .container{ height: 100%;
                .sticky-pill{display:none; box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08); padding: 10px 24px 10px 10px; 
                    &:first-child{background-color: transparent; box-shadow: none !important;
                        .sticky-pill__icon{
                            &.gold{
                                height:50px; width:50px;   
                            }
                        }                      
                        @include mixins.mq(1200){position: absolute; bottom: -12px; left: 16px; z-index: 2; padding: 0 !important;}
                    }
                }

                .result-details{
                    @include mixins.mq(1200){flex-direction: column; gap: 10px; align-items: flex-end;
                        &>div{
                            &:first-child{width: 100%; border-bottom: 1px solid #D3D4D5; padding-bottom: 15px; padding-inline: 16px;
                                @include mixins.mq(1200) { border:0; padding-bottom:0; }
                                ul{overflow-x: auto;
                                    li{flex-shrink: 0;}
                                    @include mixins.mq(600){width:100%;}
                                }                                   
                            }
                            &:nth-child(2){
                                @include mixins.mq(1200){padding: 0 16px;justify-content: space-between; width: 100%;}
                            }
                        }
                    }   
                }
            }
            @include mixins.mq(1200){padding-inline:0; height:96px; padding:12px 16px; }
        }  
        @include mixins.mq(1600){padding: 0 20px 29px 70px;}
        @include mixins.mq(1200){padding: 0 20px 29px 20px;}
        @include mixins.mq(1230){
            .container{gap:10px;}
        }
        @include mixins.mq(600){padding:0; width:100%;}
       

        //vue transitions (name="fade")
        /* .sticky-form-enter-active, .sticky-form-leave-active{
            @include mq(1200){transition: opacity 0.3s, transform 0.3s ease-out; opacity:0; transform: translateY(4px);}
        } */
    
    }
    .sticky-pill{display: flex; align-items: center; bottom:0; background-color: var(--color-yellow-light); border-radius: 8px; padding:0px 25px 0 25px; cursor: pointer; position: relative; box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15); transition: 0.4s ease-out;  //animation: showPills 0.4s ease-out 0s both; transform: translateY(155px);
        &.loading-pill{cursor: default; position: absolute; bottom: 29px; pointer-events:none; animation: hidePillTemp 2s; min-height: 75px;
            label{font-weight: 400 !important; font-size: clamp(1.4rem, 1.8vw, 1.8rem)!important; text-transform: none !important;}
            img{margin-right:13px;}
            @include mixins.mq(1200){min-height: 72px;}
        }
        &.hidePill{bottom:-125px;}
        .loading{animation: loading 1.4s linear infinite;} //loading spinner   
        &.search-unavailable{padding: 0px 25px 0 225px !important; box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3) !important; min-height: 75px !important;
            #search-unavailable{
                #search-unavailable-message{border-radius: 8px; background-color: #E5E5E5; pointer-events: all;
                    label {font-size: 1.6rem;}
                    p {font-size: 1.4rem;}
                }
            } 
            .pill-info{display: flex !important;}  
            .sticky-pill__icon.gold{margin-right: 13px !important; height: auto !important; width: auto !important;}        
        } 
        @include mixins.mq(1200){ padding:0px 25px 0 25px}
        @include mixins.mq(600){border-radius:0px;
            svg { margin-left: 10px; }
        }
        @include mixins.mq(374){ padding:0 25px 0 10px}
    }
}

@keyframes spin {    
    to {transform: rotate(360deg);}
}

