@use "_mixins";
@use "_extends";
@use "_colors";
.offset-carousel{display:flex; align-items:center;
    .text-card{flex: 0 0 385px; margin-right:60px;
        h2{@include mixins.font(3.2, 5.2, 1, 400, -0.08rem, var(--color-off-black), "Magazine Grotesque"); margin-bottom: 23px; text-transform: uppercase;
           @include mixins.mq(630){margin-bottom: 12px;}
        }
        p{@include mixins.font(1.4, 1.6, 1.5, null, null, var(--color-off-black));
          @include mixins.mq(630){line-height:145%;}
        }
        @include mixins.mq(1100){flex: 0 0 250px;}
        @include mixins.mq(630){flex:none;}
    }
    .swiper{width: calc(100% - 385px); 
        .swiper-button-next{right:71px;}
        .swiper-button-prev{left:20px;}
        .swiper-button-next, .swiper-button-prev{margin-top:-20px;}
        @include mixins.mq(1100){width: calc(100% - 250px);}       
    }
    .offset-carousel__swiper{       
        .swiper-wrapper{
            .offset-carousel__card{position:relative; width:280px; border-radius:8px; overflow: hidden; padding:20px; height:441px; display:flex; flex-direction:column; justify-content:flex-end;
                label{@include mixins.font(3.6, 3.6, null, null, null, white, "Magazine Grotesque"); cursor: pointer;}
                p{@include mixins.font(1.4, 1.6, 2.24rem, null, null, white); margin-top: 3px;}
                label, p{z-index:2;}
                &:after{content:""; position:absolute; z-index:1; bottom:0; left:0; right:0; height:200px; background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%); display:block;}
                @include mixins.mq(760){width:220px;}
                @include mixins.mq(630){height:360px;}
            }
        }
        .swiper-button-prev, .swiper-button-next{@extend %swiper-button; right:-15px;}
        .swiper-button-prev{@extend %swiper-button-prev; left:-20px;
            @include mixins.mq(375){left:-10px;}
        }
        .swiper-button-next{
            @include mixins.mq(860){right:10px;}
            @include mixins.mq(630){right:-20px;}
            @include mixins.mq(375){right:-10px;}
        }
    }   
    @include mixins.mq(630){flex-direction:column; gap:20px; padding-right: 0 !important; padding-top: 50px !important; padding-bottom: 70px !important;
        .text-card{margin-right: 0; text-align: center;}
    }
}