@use "_mixins";
@use "_extends";
@use "_colors";
.overlap-search{height:80px; margin:-166px auto 0; border-radius:8px; padding:12px 16px; gap:16px; max-width:478px; width:calc(100% - 60px); background-color:white; position:relative; z-index: 2; box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05); display:flex; align-items:center; justify-content:space-between; 
    &>div{flex:1;
        label{@include mixins.font(1.2, 1.4); font-weight:700; line-height: 20px; }
        input{display: block; border: none; padding:0; width:100%; line-height:26px; }
    }

    @include mixins.mq(840){
        height:80px;
        &>div{
            input{text-overflow: ellipsis; overflow: hidden; white-space: nowrap; @include mixins.placeholder(){font-size: 1.6rem};}
        }       
    }

    @include mixins.mq(850){       
        height:64px;
        width: 311px;
        margin:-135px auto 0;
    }



    @include mixins.mq(374){       
        width: 260px;
    }
}
.overlap-feefo { z-index: 2; position: relative; margin: 24px auto 0px; padding-bottom: 40px;
    @include mixins.mq(850){padding-bottom: 25px;}
    .feefo-rating { display:flex; align-items:center; width:230px; margin:0 auto; position: relative;
        .svg { position:absolute; top:0; right:0; }
    }
}

