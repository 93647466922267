
//css from WTP + overwrites
.hero-slider__slide__text{height: calc(100% - 0px) !important; padding: 0 40px; gap:15px 20px; display: grid !important; grid-template: auto auto auto 40px / auto; justify-items: center; align-items: center !important; align-content: center;
 
    &>h1, &>h2, &>h3, &>h4, &>h5{padding: 0 !important; max-width: 1200px !important; font-size: clamp(3rem, 5vw, 8rem) !important; text-align: center; text-transform: uppercase; line-height: 1; font-style: normal; font-weight: 700; letter-spacing: 1.6px;      
        span{color: inherit; text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.20); max-width: inherit; text-align:inherit; font-size: inherit; font-style: inherit; font-weight:inherit; line-height: inherit; letter-spacing: inherit; text-transform: inherit; }
    }
     
    .roundel-img{width: 11vw; max-width: 220px; min-width: 120px; align-self:center;
        @media(max-width:500px){min-width: 90px;}
    }

    &>p, .hero-slider__slide__cta-link{margin: 0 !important; margin-top: 0 !important;}
    .hero-slider__slide__cta-link{font-size: clamp(1.4rem, 1.6vw, 1.6rem) !important;}

    @media(max-width:1000px){
        &:has(.roundel-img) {
            height: calc(100% - 75px) !important;
        }       
    }   
    
    &:has(p, .roundel-img) {padding-bottom:clamp(4rem, 9.7vw, 10.7rem) !important; 
        @media(max-width:500px){padding-bottom: 0 !important;}
    }  

    &:has(.hero-slider__slide__cta-link) {padding-bottom:clamp(4rem, 9.7vw, 16.7rem) !important; 
        @media(max-width:500px){padding-bottom:clamp(4rem, 9.7vw, 16.7rem) !important; }
    } 

    @media(min-width:501px){grid-template: auto auto auto / auto auto; padding: 0 70px; gap:0 15px;
        .roundel-img{transform: translateY(-25%);}
        &>a, .roundel-img{grid-column: 1 / 2; grid-row: 1 / 4;}
        &>h1, &>h2, &>h3, &>h4, &>h5{grid-column: 2 / 3; grid-row: 1 / 2; min-width: 350px;}
        &>p{grid-column: 2 / 3; grid-row: 2 / 3; margin-top: 15px !important;}
        .hero-slider__slide__cta-link{grid-column: 2 / 3; grid-row: 3 / 4; margin-top: 15px !important;}
        &:has(.roundel-img){
            &>p, .hero-slider__slide__cta-link{transform: translateX(-6.2vw);}
        }       
    }
}

