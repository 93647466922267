@use "_mixins";
@use "_extends";
@use "_colors";

.hero{margin: 0 25px; border-radius: 10px; overflow: hidden; height: calc(100vh - 60px); flex-shrink:unset; position: relative; display:flex; flex-direction:column; justify-content:center; @include mixins.bgRatio(465, 700, 41);
    .hero-content{max-width:1100px; margin: 0 auto; padding:0 40px; text-align:center; position: relative; z-index: 2;
        h1{@include mixins.font(3.8, 7.5, 1, 700, null, var(--color-yellow), "Magazine Grotesque"); letter-spacing:0.1rem; text-transform: uppercase;}
        p{font-size: clamp(1.2rem, 2vw, 2rem); line-height: 140%; padding: 0 50px; color: white;
            span{font-size: clamp(1.2rem, 2vw, 2rem); line-height: 140%;}
        }        
        h3{
            span{font-size: clamp(3rem, 6vw, 6rem); line-height: 120%; display: block;}
            @include mixins.mq(800){margin-bottom: 10px;}
        }     
        ul.vertical-crumbs { flex-wrap: wrap; justify-content: center; }
        @include mixins.mq(500) { padding: 20px; }
    }  
    &:before{
        content:"" !important; position:absolute; top:0; right:0; bottom:0; left:0; background-color: rgba(black, 0.2); pointer-events: none;
    }  
    @include mixins.mq(860){margin: 0 42px;}
    @include mixins.mq(620){margin: 0 30px;}
    @include mixins.mq(374){margin: 0 20px;}
}

.swiper{    
    .swiper-arrow{margin-top: 0;}
}

.home-hero{text-align: center; margin-top: 60px;
    .logo{
        &>div{height:70px; width:197px; margin:0 auto;}
    }
    .home-hero-text{margin-top: 130px;
        p{@extend %text-large; max-width: 735px; margin: 28px auto 0; font-size: clamp(1.6rem, 2.2vw, 2.2rem); line-height: 144%; color: colors.$darkGray; letter-spacing: -0.09rem;}
        h1{font-size: clamp(3.6rem, 6.2vw, 6.2rem); line-height: 100%; letter-spacing:-0.04rem}
        @include mixins.mq(620){margin-top: 40px;
            p{margin: 25px auto 0;}
        }
    }
    .home-hero-cta{display: flex; justify-content: center; gap:18px; margin-top: 82px;
        @include mixins.mq(620){flex-direction: column; margin-top: 50px;}
    }

    @include mixins.mq(860){margin-top: 74px;
        .logo{
            img{width:166px;}
        }
        .home-hero-text{margin-top: 46px;}
    }

    @include mixins.mq(620){margin-top: 30px;
        .logo{
            img{width: 130px;}
        }
    }

     @include mixins.mq(520){margin-top: 80px;
        .logo{
            img{width:144px;}
        }
        .home-hero-text{margin-top: 40px; max-width: 280px; margin: 38px auto 0;
            p{margin: 35px auto 0;}
        }
    }
   
}
