@use "_mixins";
@use "_extends";
@use "_colors";
.start-conversation{display:flex; gap: 36px;
    &__text{flex:1; padding: 33px 0;
        h4{@include mixins.font(3.2, 4.5, 135%, 400, null, null, "Magazine Grotesque"); max-width: 440px; margin-bottom: 47px;}
        p{@include mixins.font(1.6, 1.6, 2.56rem, 400, null); max-width: 510px;}
        ul{gap: 21px;
           &.contacts{margin: 20px 0 47px; display:flex;
                @media(max-width:700px){flex-direction: column; gap:10px;}
                @media(max-width:600px){flex-direction: row; gap:20px; margin-top: 25px; margin-bottom: 20px;}
                @media(max-width:360px){flex-direction: column; gap:10px;}
            }
            li{@include mixins.font(1.8, 1.8, 140%, 400, null, black); display:flex; align-items:center; gap: 11px; font-weight:600; cursor:pointer;
               a{display:flex; align-items:center; color:inherit; gap: 11px; font-weight:600;}
            }
            &.cta{margin: 20px 0 0;display: flex; flex-direction: column; gap: 22px;
                li{
                    &:first-child{
                        a{gap:18px;}
                    }
                }
            }
        }
    }
    &__grid{flex:1; display:grid; grid-template: 430px 306px  / repeat(2, 1fr); gap:13px;
        &>div{
            &:first-child{grid-column: 1/3; grid-row: 1/2;}
            &:nth-child(2){grid-column: 1/2;}
            &:last-child{grid-column: 2/3;}
        }
        @include mixins.mq(850){grid-template: 320px 176px  / repeat(2, 1fr); gap:10px;}
        @include mixins.mq(520){margin-left: -30px; width: calc(100% + 60px);}
        @include mixins.mq(374){margin-left: -20px; width: calc(100% + 40px);}
    }   

    @include mixins.mq(600){flex-direction: column;
        h4{margin-bottom: 10px;}
    }
}
