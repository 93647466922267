@use "../components/_mixins";
@use "../components/_extends";
@use "../components/_colors";
@use "../components/_main";
@use "../components/_titles";
@use "../components/_heroes";
@use "../components/_overlapSearch";
@use "../components/_offsetCarousel";
@use "../components/_tabs";
@use "../components/_ourPicks";
@use "../components/_uspTitles";
@use "../components/_accreditations";
@use "../components/_cards";
@use "../components/_startConversation";
@use "../components/_modal";
@use "../components/_stickyFooterSearch";

.carousel-overflow{padding: 82px 77px 82px 0;
    @include mixins.mq(540){padding: 60px 77px 60px 0;}
}

// .tabs{padding: 84px 0 0;
//     @include mixins.mq(840){padding: 50px 0 0 !important;}
// }
.tab-content{
    &.picked{margin-top: 45px;}
}

.our-picks .our-picks-swiper, .offers-swiper {padding-bottom: 84px;
    @include mixins.mq(500){padding-bottom: 50px;}
}

.title{
    &.difference{padding: 82px 0 61px;
        h2{@include mixins.font(3.6, 4.5, null, 400, null, colors.$darkGray, "Magazine Grotesque"); letter-spacing: 0rem;}
        @include mixins.mq(500){padding-top: 60px; padding-bottom: 30px;}
    }
    @include mixins.mq(540){padding: 60px 77px 60px 0;}
}

.usp-titles{padding-bottom:87px;
    @include mixins.mq(600){padding-bottom: 30px;}
}

.accreditations{padding-bottom: 121px;
    @include mixins.mq(600){padding-bottom: 90px;}
}

.three-col{padding-bottom: 76px;
    @include mixins.mq(600){padding-bottom: 60px;}
}

button.grey.square{padding:20px 29px 20px; display:block; height: 68px;
    span{font-size: 1.8rem;}
}

.start-conversation{padding: 40px 0 80px; margin-top: 40px;
    @include mixins.mq(600){
        .start-conversation__text{padding: 0;}
    }
}

main{
    .collection-btn{
        &.center{margin-bottom: 60px;
            @include mixins.mq(860){margin-bottom: 40px;}
            @include mixins.mq(500){margin-bottom: 30px;}
        }
    }
}
