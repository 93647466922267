@use "_mixins";
@use "_extends";
@use "_colors";
.usp-titles{display:flex; gap:80px;
    .usp-title{flex:1; 
        h3{@include mixins.font(2.4, 2.8, null, null,-0.05rem, colors.$darkGray, "Magazine Grotesque");}
        p{@include mixins.font(1.4, 1.4, 2.1rem, null, 0.02rem); margin-top: 15px;}
        &:nth-child(2){position:relative;
            &:before, &:after{content:""; top:0; bottom:0; position:absolute; border-left:1px solid rgba(black, 0.2);}
            &:before{left:-39px;}
            &:after{right:-39px;}
        }
        .accreditation{display:flex; gap:34px; margin-top:21px;}
    }
    @include mixins.mq(920){gap:60px;
        .usp-title{
            &:nth-child(2){position:relative;            
                &:before{left:-29px;}
                &:after{right:-29px;}
            }
        }
    }
    
    @include mixins.mq(720){flex-direction: column; gap:30px;
        .usp-title{text-align: center;
            &:nth-child(2){           
                &:before, &:after{content: none;}
            }
            .accreditation{justify-content: center;}
        }
    }
}